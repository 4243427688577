$title-width: 600px;

.library {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: calc(100vh - 50px);
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    height: auto;
  }
  &__main-title {
    width: $title-width;
    font-size: 40px;
    line-height: 1;
    @media screen and (max-width: 1024px) {
      width: 100%;
      padding-top: 100px;
      text-align: center;
      font-size: 30px;
    }
  }
  &__status-wrap {
    width: calc(100% - #{$title-width});
    height: 100%;
    padding: 100px 0;
    overflow-y: scroll;
    @media screen and (max-width: 1024px) {
      width: 100%;
      padding: 100px 0 20px;
    }
  }
  &__status-frame {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: auto;
    padding: 80px 100px;
    line-height: 1;
    @media screen and (max-width: 1024px) {
      padding: 0 40px 40px;
    }
    @media screen and (max-width: 480px) {
      padding: 0 20px 20px;
    }
  }
  &__list {
    display: block;
    @media screen and (max-width: 1024px) {
      width: 100%;
      max-width: 500px;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    @media screen and (max-width: 1024px) {
      justify-content: space-between;
    }
    &:not(:nth-of-type(1)) {
      margin-top: 40px;
    }
    &-title,
    &-data {
      display: block;
      line-height: 2;
    }
    &-title {
      width: 300px;
      @media screen and (max-width: 1024px) {
        width: auto;
      }
    }
    &-data {
      width: calc(100% - 300px);
      @media screen and (max-width: 1024px) {
        width: auto;
      }
    }
  }
}
