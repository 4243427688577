.header {
  display: block;
  width: 100vw;
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  @media screen and (max-width: 768px) {
    padding: 0 5px;
  }
  &__title {
    width: 135.48px;
    height: 25px;
  }
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1920px;
    width: 100%;
    height: 50px;
    margin: auto;
  }
  &__navigation {
    display: block;
    @media screen and (max-width: 768px) {
      display: none;
    }
    &-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-item {
      display: block;
      margin-left: 30px;
      &--selected {
        position: relative;
        z-index: 1;
      }
    }
    &-link {
      font-size: 14px;
      line-height: 1;
      transition: 0.3s;
      position: relative;
      z-index: 1;
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: #000;
        transition: 0.3s;
        transform: scaleX(0);
        transform-origin: left;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      &:hover {
        &::after {
          transform: scaleX(1);
        }
      }
      &--selected {
        color: #fff;
      }
    }
    &-background {
      width: calc(100% + 30px);
      height: 38px;
      background-color: #000;
      position: absolute;
      top: -11px;
      left: -15px;
      z-index: 0;
    }
  }
}

.header-sp {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
  &__navigation-button {
    display: block;
    width: 40px;
    height: 40px;
    background: #000;
    position: fixed;
    bottom: 5px;
    right: 5px;
    z-index: 10;
    cursor: pointer;
    border-radius: 50%;
  }
  &__navigation-line {
    display: block;
    width: 20px;
    height: 1px;
    background: #fff;
    position: absolute;
    left: 10px;
    transition: 0.3s;
    &:nth-of-type(1) {
      top: 15px;
    }
    &:nth-of-type(2) {
      top: 20px;
    }
    &:nth-of-type(3) {
      top: 25px;
    }
    &--on {
      &:nth-of-type(1) {
        top: 20px;
        transform: rotate(45deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        top: 20px;
        transform: rotate(-45deg);
      }
    }
  }
  &__navigation-bg {
    position: fixed;
    bottom: 5px;
    right: 5px;
    z-index: 0;
    width: 40px;
    height: 40px;
    background: #000;
    border-radius: 50%;
    overflow: hidden;
    opacity: 1;
  }
  &__navigation {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }
  &__navigation-item {
    display: flex;
    align-items: center;
    &:before {
      content: '';
      display: block;
      width: 10px;
      height: 1px;
      margin-right: 10px;
      background-color: #fff;
    }
    &:not(:nth-of-type(1)) {
      margin-top: 40px;
    }
  }
  &__navigation-link {
    color: #fff;
  }
}
