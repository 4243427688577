.contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    height: auto;
  }
  &__main-title {
    font-size: 40px;
    line-height: 1;
    @media screen and (max-width: 1024px) {
      font-size: 30px;
    }
    @media screen and (max-width: 480px) {
      width: 100%;
      padding-top: 100px;
      text-align: center;
    }
  }
  &__sns-frame {
    display: flex;
    align-items: center;
    line-height: 1;
    @media screen and (max-width: 480px) {
      justify-content: center;
      width: 100%;
      padding: 100px 0 20px;
    }
  }
  &__sns-link {
    line-height: 1;
    &:not(:nth-of-type(1)) {
      margin-left: 20px;
    }
  }
  &__sns-image {
    width: 50px;
    height: 50px;
  }
}
