.top {
  display: block;
  width: 100%;
  height: 100%;
  &__wrap {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: auto;
  }
  &__title {
    font-size: 50px;
    line-height: 1.5;
    margin-bottom: 15vh;
    @media screen and (max-width: 1024px) {
      font-size: 40px;
    }
    @media screen and (max-width: 480px) {
      font-size: 30px;
    }
    &--small {
      font-size: 16px;
      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }
      @media screen and (max-width: 480px) {
        font-size: 12px;
      }
    }
  }
}
