$title-width: 300px;
$image-frame-height: 500px;

.works {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: calc(100vh - 50px);
  position: relative;
  z-index: 0;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    height: auto;
  }
  &__main-title {
    width: $title-width;
    font-size: 40px;
    line-height: 1;
    @media screen and (max-width: 1024px) {
      width: 100%;
      padding-top: 100px;
      text-align: center;
      font-size: 30px;
    }
  }
  &__list {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    width: calc(100% - #{$title-width});
    height: 100%;
    overflow-y: scroll;
    @media screen and (max-width: 1024px) {
      width: 100%;
      margin-top: 100px;
    }
  }
}
.work {
  display: block;
  flex: 0 0 40%;
  max-width: 40%;
  padding-bottom: 20px;
  transition: 0.3s;
  @media screen and (max-width: 1024px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media screen and (max-width: 480px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  &:nth-of-type(odd) {
    padding-right: 10px;
    @media screen and (max-width: 480px) {
      padding-right: 0;
    }
  }
  &:nth-of-type(even) {
    padding-left: 10px;
    @media screen and (max-width: 480px) {
      padding-left: 0;
    }
  }
  &:nth-child(4n + 1),
  &:nth-child(4n + 4) {
    flex: 0 0 60%;
    max-width: 60%;
    @media screen and (max-width: 1024px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media screen and (max-width: 480px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  &__close-button {
    display: block;
    width: 30px;
    height: 30px;
    background: #000;
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 3;
    cursor: pointer;
    &::after,
    &::before {
      content: '';
      display: block;
      width: 25px;
      height: 1px;
      margin: auto;
      background-color: #fff;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    &::after {
      transform: rotate(45deg);
    }
    &::before {
      transform: rotate(-45deg);
    }
  }
  &__delete-info {
    z-index: 2;
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    will-change: opacity;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  &__frame {
    width: 100%;
    max-width: 1000px;
    height: 300px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid #ccc;
    @media screen and (max-width: 1024px) {
      height: 250px;
    }
    &.work__frame--open {
      @media screen and (max-width: 1200px) {
        width: calc(100% - 40px);
      }
    }
    &--open {
      height: 80vh;
      margin: auto;
      background-color: #fff;
      position: fixed;
      top: 10vh;
      left: 0;
      right: 0;
      z-index: 2;
      cursor: default;
      .work__info-frame {
        height: 500px;
        bottom: 10vh;
        left: 50px;
        @media screen and (max-width: 1024px) {
          height: 50vw;
          left: 30px;
        }
        @media screen and (max-width: 480px) {
          left: 20px;
        }
      }
      .work__image-frame {
        opacity: 1;
        background-position: top center;
        @media screen and (max-width: 1024px) {
          height: 50vw;
        }
      }
    }
  }
  &__image-frame {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: $image-frame-height;
    max-height: 50vh;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    @media screen and (max-width: 1024px) {
      height: 100%;
    }
  }
  &__info-frame {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    max-width: calc(100% - 50px);
    max-height: calc(100% - 50px);
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
  }
  &__date {
    display: inline;
    padding: 5px 10px 3px;
    color: #fff;
    font-size: 12px;
    letter-spacing: 0.2em;
    background-color: #000;
    @media screen and (max-width: 480px) {
      font-size: 10px;
    }
  }
  &__title {
    display: inline;
    padding: 5px 8px 3px;
    color: #fff;
    font-size: 20px;
    line-height: 1.8;
    background-color: #000;
    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }
  &__description {
    font-size: 14px;
    line-height: 2;
    @media screen and (max-width: 480px) {
      font-size: 12px;
    }
  }
  &__description-wrap {
    width: calc(100% - 100px);
    height: 50vh;
    overflow-y: scroll;
    position: absolute;
    z-index: 0;
    top: calc(500px + 50px);
    left: 50px;
    @media screen and (max-width: 1024px) {
      width: calc(100% - 60px);
      top: calc(50vw + 30px);
      left: 30px;
    }
    @media screen and (max-width: 480px) {
      width: calc(100% - 40px);
      top: calc(50vw + 20px);
      left: 20px;
    }
  }
  &__button {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 10px;
    background: #fff;
    // color: #fff;
    font-size: 14px;
    border: 1px solid #000;
    transition: 0.3s;
    &:hover {
      color: #fff;
      background: #000;
    }
  }
}
