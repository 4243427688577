.layout {
  width: 100vw;
  height: auto;
  min-height: 100vh;
  padding: 50px 20px 0;
  margin: 0;
  &__main {
    display: block;
    width: 100%;
    max-width: 1200px;
    height: calc(100vh - 50px);
    margin: auto;
    overflow-y: scroll;
  }
}
