$title-width: 300px;

.profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: calc(100vh - 50px);
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    height: auto;
  }
  &__main-title {
    width: $title-width;
    font-size: 40px;
    line-height: 1;
    @media screen and (max-width: 1024px) {
      width: 100%;
      padding-top: 100px;
      text-align: center;
      font-size: 30px;
    }
  }
  &__status-wrap {
    width: calc(100% - #{$title-width});
    height: 100%;
    padding: 100px 0;
    overflow-y: scroll;
    @media screen and (max-width: 1024px) {
      width: 100%;
      padding: 100px 0 20px;
    }
  }
  &__status-frame {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: auto;
    padding: 80px 100px;
    line-height: 1;
    border: 1px solid #ccc;
    @media screen and (max-width: 1024px) {
      padding: 60px 40px;
    }
    @media screen and (max-width: 480px) {
      padding: 40px 20px;
    }
  }
  &__image {
    width: 100%;
    height: auto;
    &-wrap {
      display: block;
      width: 230px;
      height: 230px;
      margin-bottom: 80px;
      border-radius: 50%;
      overflow: hidden;
      @media screen and (max-width: 1024px) {
        width: 180px;
        height: 180px;
        margin-bottom: 60px;
      }
      @media screen and (max-width: 480px) {
        width: 115px;
        height: 115px;
        margin-bottom: 30px;
      }
    }
  }
  &__list {
    display: block;
  }
  &__item {
    display: flex;
    align-items: center;
    @media screen and (max-width: 480px) {
      flex-direction: column;
    }
    &:not(:nth-of-type(1)) {
      margin-top: 40px;
      @media screen and (max-width: 1024px) {
        margin-top: 30px;
      }
    }
    &-title,
    &-data {
      display: block;
      line-height: 2;
      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }
    }
    &-title {
      width: 150px;
      @media screen and (max-width: 480px) {
        width: 100%;
      }
    }
    &-data {
      width: calc(100% - 150px);
      @media screen and (max-width: 480px) {
        width: 100%;
      }
    }
  }
}
